// this is just the header based on the existing data

<template>
<div class="title_container">
    <hr>
    <div class="row">
        <div class="col-12 offset-md-12">
            <GenericBtn :title="$t('bookingButtons.cancel')"  render_class="btn btn-danger" data-bs-toggle='modal' data-bs-target='#modal_container' />
            <GenericBtn :title="$t('bookingButtons.processPayment')"  render_class="btn btn-success" @click='payBooking'/>
        </div>
    </div>

</div>
</template>

<script>
import GenericBtn from './Button.vue'
export default {
    name: "PaymentFormFooter",
    components:{
        GenericBtn
    },
    props:{

    },
    methods:{
        payBooking(){
            this.$emit('payBooking')
        }
    },
    emits:['payBooking']

}
</script>

<style scoped>
.title_container{
    text-align: left;
    padding: 5px 5px 25px 10px;
    max-width: 52%;
}
.event_name_title{
    color:green;
}
@media screen and (max-width:900px) {
    .title_container{
    max-width: 100%;
}
}
</style>
