<template>
  <div class="main_container_form container generic_floating_container">
    <div class="inner_container">
      <div class="modal-tittle">
        <PaymentFormHeader :amount="'$'+parseFloat(getCreatedBooking.total_payment).toFixed(2)"
                           :event_name="event_selected.name"
                           :people_number="getCreatedBooking.number_people"
                           :loadTaxInvoice="false"
                           :selected_date="new Date(getCreatedBooking.scheduled_for).toDateString()" />
      </div>
      <div class="participat_container">
        <PaymentFormBody />
      </div>
      <div class="modal-title">
        <PaymentFormFooter @payBooking='completePayment' />
      </div>
      <ModalDialog :form_title="$t('cancelBooking.cancelTitle')"
                   action_btn_render_class="btn btn-danger"
                   :action_btn_title="$t('bookingButtons.cancelBooking')"
                   :message="$t('cancelBooking.cancelMessage') +event_selected.name+' ?'"
                   action_name="cancelBooking"
                   @performEmit="performEmit"/>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import PaymentFormHeader from "@/components/PaymentFormHeader.vue";
import PaymentFormBody from "@/components/PaymentFormBody.vue";
import PaymentFormFooter from "@/components/PaymentFormFooter.vue";
import ModalDialog from "@/components/modalDialog.vue";

export default {
  name: 'complete_payment',
  components: {ModalDialog, PaymentFormFooter, PaymentFormBody, PaymentFormHeader},
  data() {
    return{
      isLoading: true,
    }
  },
  methods:{
    ...mapActions(['getBookingHold','resetBookingInfo', 'setCoralBotProps', 'createParticipants', 'updateBooking', 'holdTimerConfig']),
    async completePayment(){
      const full_validated = await this.$store.dispatch('runValidations',
        'PaymentForm');

      if(full_validated.passed)
      {
        this.$store.dispatch('completeBookingPayment').then((response)=>{
          if (response.status){
            this.$toast.add({severity:'success',
              summary: this.$t('bookingPayment.paymentCompleted'),
              detail: response.message,
              life:7000})
              this.$store.dispatch('updateBooking').then((response) => {
                    if(response.status){
                      this.$store.dispatch('holdTimerConfig', false).then(() => {
                          // here we redirect to the confirmation
                          this.$router.push({name:'payment_confirmation',
                                prop_id:this.prop_token,
                                event_id:this.event_selected.productId})
                        });
                    }
                    else{
                            // here we use toastr for displaying the error
                            this.$toast.add({severity:'error',
                                            summary: this.$t('bookingNotification.errorMessage'),
                                            detail: response.message,
                                            life:7000})
                    }
                });
          }
          else{
            this.$toast.add({severity:'error',
              summary: this.$t('bookingPayment.paymentError'),
              detail:response.message,
              life:7000})
          }
        }).catch((response)=>{
          this.$toast.add({severity:'error',
            summary: this.$t('bookingPayment.paymentError'),
            detail: response,
            life:7000})
        })
      }
      else{
        // here we use toastr for displaying the error
        this.$toast.add({severity:'error',
          summary: this.$t('bookingPayment.paymentError'),
          detail: full_validated.error,
          life:7000})
      }
    },
  },
  computed:{
    ...mapGetters(['event_selected', 'getCreatedBooking','guests', 'getEventList',]),
  },
}
</script>

<style scoped>
.cancelation_policy{
  text-align: left;
  padding: 5px 5px 5px 5px;

}
.participants{
  text-align: left;
  padding: 5px 5px 5px 5px;
}
.form_title{
  text-align: left;
  padding: 25px 5px -5px 11px;
  color: #8c8c8c;

}
.main_container_form{
  background-color: #f7f7f7;
  padding: 0px 10px 0px 15px;
  /* max-width: 50%; */
}
.primary_col{
  max-width: 38%;
}
@media screen and (max-width:900px) {
  .primary_col{
    max-width: 100%;
  }
  .main_container_form{
    max-width: 90%;

  }
}
@media only screen and (min-width: 900px) {
  /* .main_container_form{
        max-width: 70%;

    } */
}
</style>
