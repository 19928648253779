<template>
  <div class="text-end ms-auto" style="width: fit-content;">
    <label for="selectLanguage" class="form-label d-block"><b>{{ $t('propertyHeader.chooseLanguage') }}</b></label>
    <select class="form-select mb-3 w-100" id="selectLanguage" v-model="selectedLanguage" @change="translateI18n">
        <option v-for="(language, index) in languages" :key="index" :value="language.locale">
          {{ language.name }}
        </option>
    </select>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Select your prefferred language, in order to continue with booking.</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label for="selectLanguage" class="form-label d-block"><b>{{ $t('propertyHeader.chooseLanguage') }}</b></label>
          <select class="form-select mb-3 w-100 right-0" aria-label="Default select example" v-model="selectedLanguage" @change="translateI18n">
            <option v-for="(language, index) in languages" v-bind:key="index" :value="language.locale">
                {{ language.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapGetters, mapActions } from 'vuex';

export default {

name: "TranslationMenu",
data: () => ({
  languages: [
    {
      name: 'English',
      locale: 'en_US',
      country: 'us',
      fallback: true,
    },
    {
      name: 'Español',
      locale: 'es_ES',
      country: 'do',
    },
  ],
  selectedLanguage: '',
  modalInstance: undefined,
}),
computed: {
  ...mapGetters(['language_selected']),
},
methods: {
  ...mapActions(['changeLanguageSelected']),
  translateI18n(value) {
    console.log('Selected language', value);
    const lang = value.target.value;
    this.$i18n.locale = lang;
    window.localStorage.setItem('currentLanguage', lang);
    if (this.modalInstance) {
      this.modalInstance.hide();
    }
    this.$store.dispatch('changeLanguageSelected', lang);
  },
},
mounted() {
  if (window.localStorage.currentLanguage === undefined) {
    const modalElement = document.getElementById('exampleModal');
    this.modalInstance = new Modal(modalElement);
    this.modalInstance.show();
    return;
  } else {
    this.selectedLanguage = window.localStorage.currentLanguage;
    this.$store.dispatch('changeLanguageSelected', this.selectedLanguage);
  }
},
}
</script>
<style>
</style>
