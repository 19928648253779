// this is just the header based on the existing data

<template>
  <div class="title_container">
    <h2 class="form_title">
      <b>
        {{ $t('bookingPayment.paymentTitle') }}
      </b>
    </h2>
    <h3 class="event_name_title">
      {{event_name}}
    </h3>
    <h5 v-if="event_selected.bookingEventConfiguration.is_multi_days">
                    {{ event_selected.bookingEventConfiguration.multi_day_config.start_date }} - {{ event_selected.bookingEventConfiguration.multi_day_config.end_date }}
                </h5>
                <h5 v-else>{{ formattedDate() }}</h5>
    <div class="container mt-4">
      <div class="row">
        <div class="col col-xs-2">
          <h6><strong>{{ $t('bookingPayment.reservation') }}</strong></h6>
        </div>
        <div class="col col-xs-2">
          <h6>{{ people_number }} Pax</h6>
        </div>
        <div class="col col-xs-2">
          <h6>USD{{ amount }}</h6>
        </div>
      </div>
      <div v-if="property_fetcher.configuration?.tax_invoice">
        <div class="row" v-if="property_fetcher.configuration.tax_invoice.tax > 0">
          <div class="col">
            <h6><strong>ITBIS</strong></h6>
          </div>
          <div class="col">
            <h6>{{ property_fetcher.configuration.tax_invoice.tax }}%</h6>
          </div>
          <div class="col">
            <h6>USD${{ tax_invoice_amount.taxAmount }}</h6>
          </div>
        </div>
        <div class="row" v-if="property_fetcher.configuration.tax_invoice.service_charge > 0">
          <div class="col">
            <h6><strong>{{ $t('bookingPayment.serviceCharge') }}</strong></h6>
          </div>
          <div class="col">
            <h6>{{ property_fetcher.configuration.tax_invoice.service_charge }}%</h6>
          </div>
          <div class="col">
            <h6>USD${{ tax_invoice_amount.serviceChargeAmount }}</h6>
          </div>
          <hr/>
        </div>
        <div class="row">
          <div class="col">
            <h6>{{ $t('bookingPayment.totalAmount') }}</h6>
          </div>
          <div class="col">
          </div>
          <div class="col">
            <h6> <b>USD${{ tax_invoice_amount.totalAmount }}</b></h6>
          </div>
        </div>
      </div>
    </div>
    <div class="p-4" v-if="tax_invoice_amount.invoiceNeedId && !requiredComprobanteFiscal">
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">{{ $t('bookingPayment.attentionAlert') }}</h4>
        <p>
          {{ $t('bookingPayment.invoiceOver') }}<b> USD${{tax_invoice_amount.amountForIdentification}}</b> 
          {{ $t('bookingPayment.invoiceOverRestMessage') }}
        </p>
        <hr>
        <p class="mb-0">
          {{ $t('bookingPayment.idPetition') }}
        </p>
      </div>
      <div class="mt-3">
        <label>{{ $t('bookingPayment.idType') }}</label>
        <select
          id="customSelect"
          class="form-control custom-select wrapped-select"
          @change="onSelectDocumentType"
        >
          <option class="wrapped-option" default="true" value="">
            {{ $t('bookingPayment.selectIdType') }}
          </option>
          <option class="wrapped-option" value="rnc">
            RNC
          </option>
          <option class="wrapped-option" value="cedula">
            Cedula
          </option>
          <option class="wrapped-option" value="passport">
            {{ $t('bookingPayment.passport') }}
          </option>
        </select>
        <Input v-if="documentTypeSelected" input_type="text" label_title='Identification Number' v-model="identificationNumber"
               :validation="ruleset_validation.InvoiceRequiredIdentificationForm.identificationNumber"
               html_unique="identification_number"
               class="mt-4"
               :masked="maskIdentification"
               :required_f='false'
        />
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Input from "@/components/Input.vue";
import OptionSelect from "@/components/optionSelect.vue";

export default {
  name: "BookingFormHeader",
  components: {OptionSelect, Input},
  data() {
    return{
      documentTypeSelected: false,
      maskIdentification: '',
    };
  },
  props:{
    event_name:{
      type: String
    },
    selected_date:{
      type: String
    },
    people_number:{
      type: Number
    },
    amount:{
      type: String
    },
    loadTaxInvoice: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onSelectDocumentType(event) {

      this.documentTypeSelected = event.target.value !== "";
      if(event.target.value === 'rnc'){
        this.ruleset_validation.InvoiceRequiredIdentificationForm.identificationNumber.validators[1].error = "this value must be of 9 digits";
        this.ruleset_validation.InvoiceRequiredIdentificationForm.identificationNumber.validators[1].limit = 9;
        this.maskIdentification = '#########'
      } else if (event.target.value === 'cedula') {
        this.ruleset_validation.InvoiceRequiredIdentificationForm.identificationNumber.validators[1].error = "this value must be of 11 digits";
        this.ruleset_validation.InvoiceRequiredIdentificationForm.identificationNumber.validators[1].limit = 11;
        this.maskIdentification = '### ####### #'

      } else {
        this.ruleset_validation.InvoiceRequiredIdentificationForm.identificationNumber.validators[1] = {}
        this.maskIdentification = undefined
      }
    },
    formattedDate() {
      const locale = this.selected_language.replace('_', '-');
      return new Date(this.date_selected).toLocaleDateString(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
  },
  computed: {
    ...mapGetters(['property_fetcher', 'tax_invoice_amount', 'ruleset_validation', 'card_getter', 'getInvoiceIdentification', 'requiredComprobanteFiscal','date_selected', 'selected_language', 'event_selected']),
    identificationNumber: {
      get() {
        return this.getInvoiceIdentification.identificationNumber
      },
      set(value){
        let sender = {
          key:'identificationNumber',
          value: value
        }
        this.$store.dispatch('fillInvoiceIdentification', sender)

        const sender2 = {
          key: 'rncOrCedula',
          value: value
        };

        this.$store.dispatch('fillCompanyTaxInfo', sender2);
      }
    },
    identificationType: {
      get() {
        return this.getInvoiceIdentification.identificationType
      },
      set(value){
        let sender = {
          key:'identificationType',
          value: value
        }
        this.$store.dispatch('fillInvoiceIdentification', sender)
      }
    },
  },

}
</script>

<style scoped>
.title_container{
  text-align: left;
  padding: 10px 5px 5px 10px;
  max-width: 52%;
}
.event_name_title{
  color:green;
}
.total_liner{
  text-align: right;
}
/* .title_container{
    max-width: 100%;
} */
@media screen and (max-width:900px) {
  .title_container{
    max-width: 100%;
  }
}

.alert {
  position: static;
}
</style>
