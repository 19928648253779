<template>
  <div>

  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'external_booking_setup',
  methods: {
    ...mapActions(['getBookingHold','resetBookingInfo', 'setCoralBotProps', 'createParticipants']),
  },
  computed:{
    ...mapGetters(['event_selected', 'getCreatedBooking','guests', 'getEventList',]),
  },
  mounted() {
    const props = this.$route.params;
    this.$store.dispatch('resetBookingInfo');
    this.$store.dispatch('setCoralBotProps', {phoneNumber: props.phone_number, lang: props.lang});
    this.$store.dispatch('getEventList', props.prop_id).then(() => {
      this.$store.dispatch('getBookingHold', {hold:props.hold_id, event:props.event_id}).then((response) => {

        if (!response.status) {
          this.$router.push({'name': 'LinkExpired'})
        }
        this.$store.dispatch('getBookingById', props.booking_id).then((response) => {
          if(response.status === 404){
            this.$router.push({'name': 'no_found'})
            return;
          }
          this.$store.dispatch('createParticipants').then(() => {
            this.$router.push({
              name:'booking_detail',
              prop_id: props.prop_id
            })
          });
          this.isLoading = false;
        });
      });
    });
  },
}
</script>
<style>

</style>

