// this is the template to take care of the
// of the existing event based on the data.

<template>
  <div class="card" style="width: 18rem;" v-if="event_selected.bookingEventConfiguration.is_multi_days">
    <div class="card-body">
      <h5 class="card-title"><strong>{{$t('bookingCreate.fromDate')}}</strong> {{ event_selected.bookingEventConfiguration.multi_day_config.start_date }} <br/> <strong>{{$t('bookingCreate.toDate')}}</strong> {{ event_selected.bookingEventConfiguration.multi_day_config.end_date }}</h5>
        <p class="card-text" v-if="index_available[availability.startTime] && event_selected.bookingEventConfiguration.show_available_seats">
            {{ $t('bookingCreate.available') }} {{index_available[availability.startTime].numSeatsAvailable}}
        </p>
        <p>
          <strong class="total_amount">USD{{ availability_list[0]?.formated_price }}</strong>
        </p>
        <Button :title="$t('bookingButtons.bookNow')" class="booking_btn_component" render_class="btn btn-success booking_btn " @click="proceedBooking"/>
    </div>
  </div>
  <div class="row" v-cloak v-else>
    <div class="col-6" style="text-align: left;">
      <div>
        <h5>
          {{availability.formated_start_time}}
        </h5>
      </div>
      <p v-if="index_available[availability.startTime] && event_selected.bookingEventConfiguration.show_available_seats">
        {{ $t('bookingCreate.available') }} {{index_available[availability.startTime].numSeatsAvailable}}
      </p>
    </div>
    <div class="col-6" style="text-align: right">
      <!-- here we need to determine whether this is a free booking or not. -->
      <Button :title="$t('bookingButtons.bookNow')" class="booking_btn_component" render_class="btn btn-success booking_btn " @click="proceedBooking"/>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  name:'AvailableEvent',
  props:{
    availability:{
      type: Object
    }
  },
  computed:{
    ...mapGetters(['index_available', 'prop_token', 'event_selected', 'isCollaborator', 'property_fetcher', 'availability_list']),
  },
  components:{
    Button
  },
  methods:{
    ...mapActions(['createBookingHold']),
    proceedBooking(){
      this.$store.dispatch('createBookingHold', this.availability).then(
        (response)=>{
          if(response.status){
            // here we redirect
            this.$toast.add({severity:'success',
              summary: this.$t('bookingNotification.bookingSelected'),
              detail: response.message,
              life:7000})
            this.$store.dispatch('holdTimerConfig', true).then(() => {
              this.$router.push({name:'booking_form',
                params: {event_id: this.event_selected.productId,
                  prop_id: this.prop_token}})
            });

          }

          else{
            // here we render error.
            this.$toast.add({severity:'error',
              summary: this.$t('bookingNotification.error'),
              detail: response.message,
              life:7000})
          }
        }
      ).catch(
        (error_response)=>{
          this.$toast.add({severity:'error',
            summary:'Error',
            detail: error_response,
            life:7000})
        }
      )

    },
  }
}
</script>

<style scoped>
[v-cloak]{
  display:none;
}
.booking_btn{
  margin-top:1px;
}

.selected_date {
  color: green;
  font-size: 1.2rem;
  font-weight: 500;
}

/* Responsive font size adjustments */
@media (max-width: 1199px) {
  h5 {
    font-size: 1.4rem; /* Reduce font size for smaller screens */
  }
  .booking_btn_component {
    font-size: 0.8rem;
  }
}

</style>
