// lists all of the coming events that will be passed as a prop.
<template>
  <div class='fluid-container container main_list_container generic_floating_container'
       v-show="finished_loading">
    <div class="list_container" v-if="language_selected">
      <div style="justify-content: center;" :class="['row row-cols-1 row-cols-lg-2 row-cols-md-1 g-4', { 'justify-content-center': events.length === 1 }]">
        <Activity :key="event.productId" v-for="event in events" :event="event"/>
      </div>
    </div>
  </div>
</template>
<script>
import Activity from '../components/Event.vue'
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'EventList',
  components: {
    Activity,
  },
  props: {
    event_lists: {
      type: Array
    }
  },
  watch: {
    language_selected: {
      immediate: true,
      handler(value) {
        if (value) {
         this.onGetEventList();
        }
      },
    },
    selected_language: {
      immediate: true,
      handler(value) {
        if (value) {
         this.onGetEventList();
        }
      },
    },
  },
  methods: {
    ...mapActions(['getEventList', 'finished_loading','changeDisplayMainHeader', 'resetBookingInfo', 'verifyAccessRequestHash', 'clearCollaboratorData', 'isCollaborator'])
    ,ScheduleEvent(event_id, property_id){
      // here we send the user to the booking component with
      // selected id and everything. in this component I need to implement
      // vuex
    },
    onGetEventList() {
      var property_id = this.$route.params.prop_id;
          this.$store.dispatch('changeDisplayMainHeader', true)
          this.$store.dispatch('getEventList', property_id).then((response) => {
            if(this.$route.name  === "property_event_list" && this.property_fetcher.access_link[0].is_external) {
              if(this.property_fetcher.access_link[0].external_url){
                window.location.href = this.property_fetcher.access_link[0].external_url;
              } else {
                this.$router.push({ name: 'error_page' });
              }
            }
            this.verifyAccessRequestCollaborator();
          });
    },
    verifyAccessRequestCollaborator(){
      if (this.isCollaborator && (this.$route.params.employee_id && this.$route.params.hash_id)) {
        const access_request = {'employee_code': this.$route.params.employee_id, 'hash': this.$route.params.hash_id, 'property_token': this.$route.params.prop_id };
        this.$store.dispatch('verifyAccessRequestHash', access_request).then((response) => {console.log(response)}).catch((error) => {
          this.$store.dispatch('changeDisplayLoader', false);
          window.location.replace(process.env.VUE_APP_GH_DIGITAL_URL);
        });
      } else {
        // Clear collaborator data
        this.$store.dispatch('clearCollaboratorData');
      }
    },
  },
  mounted() {
    this.$store.dispatch('resetBookingInfo');
  },
  computed:{...mapGetters(['events', 'customEventConfiguration', 'property_fetcher', 'language_selected', 'selected_language'])},
}
</script>

<style scoped>
.list_container{
  /* margin: 10px 3% 5% 3%; */
  padding: 15px 15px 15px 15px;
  background-color: #f7f7f7;
}
[v-cloak]{
  display:none;
}

</style>
