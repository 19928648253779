<template>
  <div class="payment_form_container">
    <h3 class="form_title title_liner">
        {{ $t('bookingPayment.cardDetailsTitle') }}
    </h3>
    <div class="row">
        <div class="col-md-6 primary_col">
            <Input input_type="text"
            :label_title="$t('bookingPayment.nameOnCard')"
            v-model="nameOnCard"
            :validation="ruleset_validation.PaymentForm.name"
            html_unique="name_on_card"
            :required_f='false'
            />
            <Input input_type="tel"
             :label_title="$t('bookingPayment.card')"
             v-model="cardNumber"
             :validation="ruleset_validation.PaymentForm.number"
             :masked="getCardMask()"
             html_unique="form_number"
             :required_f='false'
             :is_bank_card='true'
             />
            <div class="row">
              <div class="col" style="text-align: left; margin-left: 10px; margin-bottom: 2rem">
                <img src="../assets/img.png" alt="brand cards" width="200px"/>
              </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <Input input_type="tel"
                       :label_title="$t('bookingPayment.expirationDate')"
                       v-model="expirationDate"
                       :validation="ruleset_validation.PaymentForm.expiration"
                       masked='## ##'
                       html_unique="expiration"
                       :required_f='false'
                       />
                </div>
                <div class="col-md-6">
                       <Input input_type="tel"
                        :label_title="$t('bookingPayment.securityCodeCard')"
                        v-model="Cvv"
                        :validation="ruleset_validation.PaymentForm.cvc"
                        html_unique="cvv"
                        :required_f='false'
                        />
                </div>
            </div>

        </div>
    </div>
  </div>

</template>

<script>
import Input from './Input.vue';
import {mapActions,mapGetters} from 'vuex';

export default {
name:'PaymentFormBody',
components:{
    Input
},
data() {
  return{
    cardbrand: '',
  }
},
computed:{
        ...mapGetters(['card_getter','ruleset_validation']),
        cardNumber: {
            get() {
                return this.card_getter.number
            },
            set(value){
                let sender = {
                    key:'number',
                    value: value
                }
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
        nameOnCard: {
            get() {
                return this.card_getter.name
            },
            set(value){
                let sender = {
                    key:'name',
                    value: value
                }
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
        Cvv: {
            get() {
                return this.card_getter.cvc
            },
            set(value){
                let sender = {
                    key:'cvc',
                    value: value
                }
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
        expirationDate: {
            get() {
                return this.card_getter.expiration
            },
            set(value){
                let sender = {
                    key:'expiration',
                    value: value
                }
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
    },
methods:{
    ...mapActions(['fillCardInfo']),
  getCardMask() {
    const visaRegex = /^4\d{0,15}/;
    const mastercardRegex = /^(5[1-5]\d{0,14}|2[2-7]\d{0,14})/;
    const amexRegex = /^3[47]\d{0,13}/;
    const discoverRegex = /^(6011\d{0,12}|622(12[6-9]|1[3-9]\d|[2-8]\d{2}|9[01]\d|92[0-5])\d{0,10}|64[4-9]\d{0,13}|65\d{0,14})/;
    const dinersRegex = /^3(0[0-5]\d{0,11}|[68]\d{0,12})/;
    const unionPayRegex = /^(62|81)\d{0,17}/;  // UnionPay, including 17 digits
    const jcbRegex = /^(352[8-9]|35[3-8][0-9])\d{0,15}/;
    const maestroRegex = /^(5018|5020|5038|6304|6759|676[1-3])\d{0,15}/;

    if (visaRegex.test(this.cardNumber)) {
      this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
      this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
      this.cardbrand = 'visa';
      return '#### #### #### ####'; // Visa
    } else if (mastercardRegex.test(this.cardNumber)) {
      this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
      this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
      this.cardbrand = 'Mastercard';
      return '#### #### #### ####'; // Mastercard
    } else if (amexRegex.test(this.cardNumber)) {
      this.ruleset_validation.PaymentForm.number.validators[1].limit = 15;
      this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 4;
      this.cardbrand = 'American Express';
      return '#### ###### #####'; // American Express
    } else if (discoverRegex.test(this.cardNumber)) {
      this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
      this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
      this.cardbrand = 'Discover';
      return '#### #### #### ####'; // Discover
    } else if (dinersRegex.test(this.cardNumber)) {
      this.cardbrand = 'Diners Club';
      if (this.cardNumber.length <= 14) {
        this.ruleset_validation.PaymentForm.number.validators[1].limit = 14;
        this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
        return '#### ###### ####'; // 14-digit Diners Club
      } else {
        this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
        this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
        return '#### #### #### ####'; // 16-digit Diners Club
      }
    } else if (unionPayRegex.test(this.cardNumber)) {
      // UnionPay cards can be 16 to 19 digits; here we handle a 17-digit example
      this.cardbrand = 'UnionPay';

      if (this.cardNumber.length > 16) {
        this.ruleset_validation.PaymentForm.number.validators[1].limit = 17;
        this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
        return '#### #### #### #### #'; // 17-digit UnionPay
      } else {
        this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
        this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
        return '#### #### #### ####'; // Default to 16-digit mask if unknown length
      }
    } else if (jcbRegex.test(this.cardNumber)) {
      this.cardbrand = 'JCB';

      this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
      this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
      return '#### #### #### ####'; // JCB, typically 16-19 digits
    } else if (maestroRegex.test(this.cardNumber)) {
      this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
      this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
      this.cardbrand = 'Maestro';
      return '#### #### #### ####'; // Maestro, typically 12-19 digits
    } else {
      this.cardbrand = 'unknown';
      // Default fallback for unknown card types
      this.ruleset_validation.PaymentForm.number.validators[1].limit = 16;
      this.ruleset_validation.PaymentForm.cvc.validators[1].limit = 3;
      return '#### #### #### ####'; // Default mask
    }
  },
}
}
</script>

<style scoped>
.title_liner{
    margin-left: 5px;
}
/* .payment_form_container{
    max-width: 100%;
} */
</style>
