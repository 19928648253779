// Basically here we will manage how to select the days and such.

<template>
  <div class="calendar_mod">
    <h4 class="card-title title">
      {{ $t('bookingCreate.pickDate') }}
    </h4>
    <DatePicker
      :is-expanded='true' :min-date=initial_date
      :modelValue=selectedDate color='green'
      :disabled-dates={} :available-dates=calendar_ready_dates
      v-model='selectedDate' :model_config=modelConfig
      @update:to-page="searchMonthData"
      ref='datepicker'
    />

    <!-- <h4>
        Selected date: {{selectedDate}}
    </h4> -->
  </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {Calendar, DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css'
import dayjs from "dayjs";

export default {
  name:"EventCalendar",
  props:{},
  components:{
    Calendar,
    DatePicker
    },
    methods:{
        ...mapActions(['selectDate','SearchEventMonthAvailability']),
        async searchMonthData(obj){
            let current_month = dayjs(this.initial_date).format('MM')
            let calculated_range = obj.month - current_month;
          if( calculated_range <= 7){
                if (!this.event_selected.bookingEventConfiguration.is_recurring_event) {
                  this.$store.dispatch('SearchEventMonthAvailability', obj);
                  this.initial_date = dayjs(this.event_selected.bookingEventConfiguration.start_time).format('YYYY-MM-DD')
                  this.$store.dispatch('selectDate', this.initial_date);
                  return;
                }

                this.$store.dispatch('SearchEventMonthAvailability', obj);

                if (this.selectDate.length>0){
                  this.selectDate.prototype.set(this.initial_date);
                }
            }
            else{
                this.$toast.add({
                  severity:'error',
                  summary: this.$t('bookingNotification.scheduleError'),
                  detail: this.$t('bookingNotification.scheduleErrorMessage'),
                  life:7000
                });
            }
        },
    },
  computed:{
    ...mapGetters(['date_selected','calendar_ready_dates', 'event_selected']),
    selectedDate:{
      get(){
        let return_value = this.date_selected;
        // if(return_value){
        //   return_value = this.initial_date
        //   console.log(return_value);
        // }
        if (!this.event_selected.bookingEventConfiguration.is_recurring_event) {
          return_value = this.event_selected.bookingEventConfiguration.start_time;
        }
        return return_value
      },
      set(value){
        if (!value){
          if(this.event_selected.bookingEventConfiguration.is_recurring_event) {
            value = this.selectedDate;
          }
        }

        this.$store.dispatch('selectDate', value)

      }
    }
  },
  data(){
    return{
      initial_date: dayjs().format('YYYY-MM-DD'),
      modelConfig:{
        type: 'string',
        mask: 'iso'
      }
    }
  },
}
</script>

<style scoped>
.title{
  text-align: left;
  margin-bottom:15px;
  margin-top:10px;

}
.calendar_mod{
  width: 100%;
  padding: 1.2rem;
}
</style>
