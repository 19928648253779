<template>
  <div class="modal modal-lg fade" :id="modal_id" data-bs-backdrop="static">
    <div class='modal-dialog modal-dialog-centered'>
      <div class="modal-content">
              <div class="modal-header">
                      <h4>
                              {{ form_title }}
                      </h4>
                        <i class="bi-x-circle" data-bs-dismiss='modal'></i>
              </div>
        <div class="modal-body container">
                         <h5>
                             {{message}}
                         </h5>
        </div>
        <div class="modal-footer">
                <GenericBtn :title="$t('bookingButtons.close')" color="grey" render_class='btn btn-secondary'
                 icon_class="bi-x-circle" data-bs-dismiss='modal' id='close_modal_btn' />
                 <GenericBtn v-if="has_action" :title="$t('bookingButtons.cancelBooking')" :render_class="action_btn_render_class"
                  @click="submitAction"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericBtn from './Button.vue'
export default {
        name:"ModalDialog",
        components:{
                GenericBtn,
                },
        props:{
                modal_id:{
                  type:String,
                  default: 'modal_container'
                },
                action_name:{
                        type:String,
                        default: ''
                },
                has_action:{
                    type: Boolean,
                    default: true
                },
               message: {
                   type: String
               },
                form_title:{
                        type:String,
                        default:'Generic Modal'
                },
                action_btn_title:{
                        type:String,
                        default:'Submit'
                },
                action_btn_color:{
                        type:String,
                        default:'green'
                },
                action_btn_render_class:{
                        type:String,
                        default:'btn btn-success'
                },
                action_btn_icon_class:{
                        type:String,
                        default:'bi bi-plus-circle'
                },

        },
        emits: ['performEmit'],
        methods:{
        submitAction(){
                //  here we need to perform the emit with the passed action_name
                //  this should execute the action declared in the component or whereever it is
                // if (confirm("Are you sure you want to delete this task?")){
                    let data = {
                        name: this.action_name
                    }
                    this.$emit('performEmit', data);

                }
        },

}
</script>

<style> 

</style>
