// this holds the cancelation policy of the company
// this is going to base on the data hold by property loaded.

<template>
  <div class="cancelation_container">
        <div class="outline_policy">
                <p v-html="property_fetcher.configuration.cancelation_policy.cancelation_notice">
                </p>
        </div>
        <label class='accept_terms'>
            <input type="checkbox" name="" id="" v-model='acceptValue' >
            {{ $t('bookingForm.acceptTerms') }}
        </label>
  </div>



</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'PolicyTerms',
    props:{

    },
    components:{

    },
    methods:{
        ...mapActions(['goAccepTerms'])
    },
    computed:{
        ...mapGetters(['terms_accepted', 'property_fetcher']),
        acceptValue:{
            get(){
                return this.terms_accepted
            },
            set(value){
                this.$store.dispatch('goAccepTerms', value)
            }
        }
    }

}
</script>

<style scoped>
.cancelation_policy{
    text-align: left;
    padding: 5px 5px 5px 5px;

}
.cancelation_container{
overflow: scroll;
}
.outline_policy{
    border: #c1c1c1 1px solid;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
    max-width:87%;
    max-height: 120px;
    overflow-y: scroll;
    font-size: 10px;
}
.accept_terms{
    margin:5px 0px 0px 0px;
}
</style>