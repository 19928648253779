<template>
    <div class="container generic_floating_container align_text_left p-4">
        <div class="modal-title">
            <BookingFormHeader  :event_name=event_selected.name
             :selected_date="new Date(date_selected).toDateString()" />
             <h4>{{ $t('participantInfo.participantTitle') }}</h4>
        </div>
    </div>
    <div class="container generic_floating_container align_text_left p-4">
        <div class="participant_container">
            <h3 class="form_title">
              {{ $t('participantInfo.completeInfo') }}
            </h3>
            <small>{{ $t('participantInfo.participant') }}</small>
            <div class="participants">
                <div class="guest_form" v-for="guest in guests" :key='guest.position'>
                    <GuestForm :guest_info="guest" :readonly_input="false"/>
                </div>
            </div>
            <hr/>
            <div class="row justify-content-end">
                <div class="col-6">
                    <GenericBtn :title="$t('bookingButtons.cancel')"  render_class="btn btn-danger" data-bs-toggle='modal' data-bs-target='#modal_container' />
                    <GenericBtn :title="$t('bookingButtons.submitParticipants')"  render_class="btn btn-success" @click='completeBooking'/>
                </div>
            </div>
        </div>
        <ModalDialog :form_title="$t('cancelBooking.cancelTitle')"
                 action_btn_render_class="btn btn-danger"
                 :action_btn_title="$t('bookingButtons.cancelBooking')"
                 :message="$t('cancelBooking.cancelMessage') +event_selected.name+' ?'"
                 action_name="cancelBooking"
                 @performEmit="performEmit"/>
    </div>

</template>
<script>

import {mapActions, mapGetters} from 'vuex';
import PaymentConfirmationFooter from '../components/PaymentConfirmationFooter.vue'
import PaymentConfirmationHeader from '../components/PaymentConfirmationHeader.vue'
import PropertyHeader from '../components/PropertyHeader.vue';
import PaymentConfirmationBody from '../components/PaymentConfirmationBody.vue'
import GuestForm from '../components/GuestForm.vue'
import BookingFormHeader from '../components/BookingFormHeader.vue'
import HotelGuestCheck from '../components/HotelGuestCheck.vue'
import BookerInformationForm from '../components/BookerInformationForm.vue'
import GenericBtn from '../components/Button.vue'
import ModalDialog from '../components/modalDialog.vue'


export default {
    name:'PaymentSuccessReciept',
    components:{
        PaymentConfirmationFooter,
        PaymentConfirmationHeader,
        PaymentConfirmationBody,
        GuestForm,
        BookingFormHeader,
        HotelGuestCheck,
        BookerInformationForm,
        GenericBtn,
        ModalDialog,
        PropertyHeader,
    },
    computed:{
        ...mapGetters(['booking_confirmation',
          'booker',
          'getCreatedBooking',
          'guests',
          'selected_spot',
          'event_selected',
          'date_selected',
          'booking',
          'booking_confirmation',
          'ruleset_validation']),
    },
    beforeRouteLeave(to, from, next) {
        if(to.name === 'payment_form' || to.name === 'property_event_list' || to.name === 'external_payment' || to.name === 'booking_completed'){
            next(true);
        }
        next(false);
    },
    methods:{
        ...mapActions(['changeDisplayMainHeader']),
        onStageBooking() {
          this.$store.dispatch('stageBookingBackend').then(
            (response) => {
              if(response.status){
                this.$toast.add({severity:'success',
                  summary:this.$t('bookingNotification.bookingSuccessFullyCreated'),
                  detail: response.message,
                  life:7000});
                this.$router.push({
                  name: 'payment_form',
                  params: { prop_id: this.prop_token, event_id: this.event_selected.productId }
                })
              }
              else{
                // here we use toastr for displaying the error
                this.$toast.add({severity:'error',
                  summary: this.$t('bookingNotification.errorMessage'),
                  detail: response.message,
                  life:7000})
              }
            }
          );
        },
        onCompleteFreeBooking() {
          this.$store.dispatch('freeBookingComplete').then(
            (response)=>{
              if(response.status){
                this.$toast.add({
                  severity:'success',
                  summary:this.$t('bookingNotification.bookingSuccessFullyCreated'),
                  detail: response.message,
                  life:7000
                });
                // here we redirect to the confirmation
                  this.$router.push({name:'booking_completed',
                    prop_id:this.prop_token,
                    event_id:this.event_selected.productId})

              }
              else{
                // here we use toastr for displaying the error
                this.$toast.add({severity:'error',
                  summary:this.$t('bookingNotification.errorMessage'),
                  detail: response.message,
                  life:7000})
              }
            }
          ).catch((error) => {console.error(error)})
        },
        performEmit(data){
            /*
                it performs the action based on existing name
                this takes an object with the name of the task
                and the item to process in case there's one.

                need to make this a promise so that it works
                asynchronously.
            */
            this.$store.dispatch('cancelBooking');
            document.getElementById('close_modal_btn').click();
            this.$toast.add({severity:'success',
                summary: this.$t('bookingNotification.bookingCanceled'),
                detail: this.$t('bookingNotification.bookingSuccessfullyCanceled'),
                life:7000})
            this.$router.push({name:'property_event_list',
                prop_id:this.prop_token});

        },
        async completeBooking(){
            const full_validated = await this.$store.dispatch('runValidations',
                                                             'BookingForm');

             if(full_validated.passed){
                  if (this.selected_spot) {
                    this.onStageBooking();
                  } else {
                    if (this.getCreatedBooking.total_payment === 0) {
                      this.onCompleteFreeBooking();
                    }else {
                      this.$router.push({
                        name: 'external_payment',
                        prop_id: this.prop_token,
                       })
                    }
                  }

             } else {
               // here we use toastr for displaying the error
                this.$toast.add({severity:'error',
                                summary:this.$t('bookingNotification.errorMessage'),
                                detail: full_validated.error,
                                life:7000})
           }

        }
    },
    created(){
        // this.$store.dispatch('changeDisplayMainHeader', false)
    }

}

</script>
<style scoped>
.align_text_left {
    text-align: left;
}
.participant_container {
    position: relative;
}

.genericBtn {
    position: absolute;
    bottom: -16px;
    right: 0;
}
</style>
