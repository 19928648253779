<template>
  <div class="container generic_floating_container">
      <PaymentConfirmationHeader
       :header_title="$t('bookingConfirmation.paymentConfirmationTitle')"/>
            <PaymentConfirmationBody
             :confirmation="booking_confirmation.confirmation"/>
      <PaymentConfirmationFooter
       :email_to="booking_confirmation.reservation_email"
       :redirect_url="booking_confirmation.redirect_url" />

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PaymentConfirmationFooter from '../components/PaymentConfirmationFooter.vue'
import PaymentConfirmationHeader from '../components/PaymentConfirmationHeader.vue'
import PaymentConfirmationBody from '../components/PaymentConfirmationBody.vue'

export default {
    name:'PaymentSuccessReciept',
    components:{
        PaymentConfirmationFooter,
        PaymentConfirmationHeader,
        PaymentConfirmationBody
    },
    beforeRouteLeave(to, from, next) {
      next(false);
    },
    computed:{
        ...mapGetters(['booking_confirmation'])
    },
    methods:{
        ...mapActions(['changeDisplayMainHeader'])
    },
    created(){
        this.$store.dispatch('changeDisplayMainHeader', false)
    }

}
</script>

<style>

</style>