export default {
  es_ES: {
    propertyHeader: {
      contactTittle: 'Para informacion adicional por favor contactarnos:',
      contactEmail: 'Correo:',
      contactPhone: 'Tel:',
      chooseLanguage: 'Selecciona un idioma:'
    },
    bookingButtons: {
      bookNow: 'Reservar ahora',
      cancel: 'Cancelar',
      cancelBooking: 'Cancelar reserva',
      completePayment: 'Completar Pago',
      submitParticipants: 'Enviar participantes',
      processPayment: 'Procesar pago',
      yes: 'Si',
      no: 'No',
      close: 'Cerrar',
      send: 'Enviar',
    },
    bookingCreate: {
      specifyAdult: 'Especifica la cantidad de adultos',
      pickDate: 'Selecciona una fecha',
      pickAOption: 'Selecciona una opcion',
      description: 'Descripción',
      noSpotAvailable: 'No hay cupos disponible',
      selectADate: 'Porfavor seleccionar una fecha.',
      availableEntries: 'Seleccione una entrada disponible',
      fromDate: 'Desde',
      toDate: 'Hasta',
      available: 'Disponible',
      kidsRestriction: 'Si tienes niños menores de 2 años, no es necesario agregarlos como participantes.',
      collaboratorAlert: 'Como colaborador, entras gratis y tienes 2 cupos mensuales a un precio preferencial',
      collaboratorAlertSecond: 'Después de usar estos cupos, se aplicará el precio regular de',
      collaboratorSlotsAlert: '',
      collaboratorAsistingQuestion: '¿Vas a asistir?',
      slotsAvailable: 'Cupos disponibles'
    },
    bookingForm: {
      formTitle: 'Porfavor complete la informacion',
      formName: 'Nombre',
      formLastname: 'Apellido',
      formEmail: 'Correo electronico',
      formEmailConfirmation: 'Confirmacion de correo electronico',
      formHomeAddress: 'Dirección de residencia (Dirección de la propiedad en Puntacana)',
      formPhoneNumber: 'Numero celular',
      formSpecialRequest: 'Solicitud especial',
      formTaxReceipt: '¿Requieres comprobante fiscal?',
      formDocumentType: 'Selecciona un tipo de documento',
      cancelPolicy: 'Politica de cancelación',
      acceptTerms: 'Al hacer clic aquí, acepto los términos.'
    },
    participantInfo: {
      participantTitle: 'Informacion de los participantes',
      participant: 'Participantes',
      completeInfo: 'Porfavor completa los datos de los participantes',
      partipantType: 'Tipo de participante',
      mySelf: 'Yo mismo',
      otherPerson: 'Otra persona'
    },
    bookingPayment: {
      paymentTitle: 'Tu pago incluye',
      reservation: 'Reservación',
      ITBIS: 'ITBIS',
      serviceCharge: 'Propina Legal',
      totalAmount: 'Monto Total',
      cardDetailsTitle: 'Detalle de la tarjeta',
      nameOnCard: 'Nombre del titular',
      card: 'Tarjeta',
      expirationDate: 'MM/YY',
      securityCodeCard: 'CVV',
      paymentCompleted: 'Pago completado',
      paymentError: 'Erro de pago',
      invoiceOver: 'Las facturas superiores a',
      invoiceOverRestMessage: '(aproximadamente 250,000.00 DOP) requieren un número de identificación válido (RNC, cédula o pasaporte) para cumplir con las regulaciones fiscales.',
      idPetition: 'Por favor, ingresa tu número de identificación para continuar con tu reserva.',
      idType: 'Tipo de identificación',
      selectIdType: 'Seleccion el tipo de identificación',
      passport: 'Pasaporte',
      attentionAlert: '¡Atención!'
    },
    bookingConfirmation: {
      paymentConfirmationTitle: 'Confirmación de pago',
      creditCard: 'Tarjeta de Credito',
      authorizationCode: 'Codigo de autorización',
      date: 'Fecha',
      bookingNumber: 'Número de reserva',
      confirmationSentTo: 'Confirmación de reserva enviado a',
    },
    bookingGeneralText: {
      loadingBooking: 'Cargando reservación'
    },
    bookingNotification: {
      loadingBooking: 'Cargando reserva',
      bookingSelected: 'Reserva seleccionada',
      bookingCanceled: 'Reserva cancelada',
      bookingSuccessfullyCanceled: 'Tu reserva ha sido cancelada con éxito',
      error: 'Error',
      errorMessage: 'Mensaje de error',
      bookingSuccessFullyCreated: '¡Reservación creada correctamente!',
      scheduleError: 'Error de programación',
      scheduleErrorMessage: 'Solo puedes reservar dentro de un rango de 6 meses a partir de la fecha actual.',
      amountExceeded: 'Cantidad de personas excedida',
      limitPaxAllow: 'La cantidad máxima de personas permitidas es',
      amountPaxError: 'Error en la cantidad de personas',
      miniumPaxError: 'La cantidad mínima de personas permitidas es 1.',
      companyInfo: '¡La información de la empresa fue encontrada!',
      companyNotFound: '¡No se pudo encontrar la información de la empresa! Por favor, verifica tu número de documento y vuelve a intentarlo.',
      bookingRefundTitle: 'Reembolso de reserva exitoso',
      bookingRefundCreated: 'Tu solicitud de reembolso ha sido creada con éxito.',
      bookingRefundError: 'Error en el reembolso de la reserva',
    },
    bookingTimer: {
      timerTitle: '¡Tu reserva expirará pronto!',
      timerSubtitle: '¿Quieres continuar?',
      timeMessage: 'Tiempo restante para completar tu reserva',
      timerUpdated: '¡El temporizador de retención de la reserva se ha actualizado con éxito!'
    },
    cancelBooking: {
      cancelTitle: 'Cancelar reserva',
      cancelMessage: '¿Estás seguro de que quieres cancelar la reserva para el evento ',
    },
    participantCategory: {
      adultsTitle: 'Especifique el número de adultos',
      childrenTitle: 'Especifique el número de niños',
      infantsTitle: 'Especifique el número de bebés y niñeras'
    },
    otpValidation: {
      otpTitle: 'Validación de Colaborador',
      employeeCode: 'Codigo empleado',
      otpSentTitle: '¡Codigo OTP Enviado!',
      otpSentMessage: 'Un codigo de verificación a sido enviado al siguiente correo electronico',
      otpSendMessageSequence: ', porfavor introducir el codigo.',
      otpCode: 'Codigo OTP'
    }
  },
  en_US: {
    propertyHeader: {
      contactTittle: 'For additional information please contact us:',
      contactEmail: 'Email:',
      contactPhone: 'Tel:',
      chooseLanguage: 'Select a language:'

    },
    bookingButtons: {
      bookNow: 'Book Now',
      cancel: 'Cancel',
      cancelBooking: 'Cancel Booking',
      completePayment: 'Complete payment',
      submitParticipants: 'Submit Participant',
      processPayment: 'Process Payment',
      yes: 'Yes',
      no: 'No',
      close: 'Close',
      send: 'Send',
    },
    bookingCreate: {
      specifyAdult: 'Specify number of Adults',
      pickDate: 'Pick a date',
      pickAOption: 'Choose available option',
      description: 'Description',
      noSpotAvailable: 'No spots available',
      selectADate: 'Please select a date',
      availableEntries: 'Select available entry',
      fromDate: 'From',
      toDate: 'To',
      available: 'Available',
      kidsRestriction: 'If you have kids below 2 years, theres no need to add them as a participant.',
      collaboratorAlert: 'As a collaborator, you enter for free and have 2 monthly slots at a preferential price',
      collaboratorAlertSecond: 'After using these slots, the regular price of',
      collaboratorSlotsAlert: 'will apply.',
      collaboratorAsistingQuestion: 'Are you going to be assisting?',
      slotsAvailable: 'Slots available'
    },
    bookingForm: {
      formTitle: 'Please complete your information',
      formName: 'Name',
      formLastname: 'Lastname',
      formEmail: 'Email',
      formEmailConfirmation: 'Email confirmation',
      formHomeAddress: 'Home Address (Property address at Puntacana)',
      formPhoneNumber: 'Phone number',
      formSpecialRequest: 'Special request',
      formTaxReceipt: 'Do you require a tax receipt?',
      formDocumentType: 'Select a document type',
      cancelPolicy: 'Cancel Policy',
      acceptTerms: 'By clicking here, I accept the terms'
    },
    participantInfo: {
      participantTitle: 'Participant info',
      participant: 'Participants',
      completeInfo: 'Please complete the participants info',
      partipantType: 'Partipant type',
      mySelf: 'Myself',
      otherPerson: 'Other person'
    },
    bookingPayment: {
      paymentTitle: 'Your payment includes',
      reservation: 'Reservation',
      ITBIS: 'ITBIS',
      serviceCharge: 'Service Charge',
      totalAmount: 'Total Amount',
      cardDetailsTitle: 'Card Details',
      nameOnCard: 'Name on card',
      card: 'Card',
      expirationDate: 'MM/YY',
      securityCodeCard: 'CVV',
      paymentCompleted: 'Payment completed',
      paymentError: 'Payment error',
      invoiceOver: 'Invoices over',
      invoiceOverRestMessage: '(approximately 250,000.00 DOP) require a valid ID number (RNC, cedula, or passport) to comply with fiscal regulations.',
      idPetition: 'Please enter your ID to continue with your reservation.',
      idType: 'Identification type',
      selectIdType: 'Please select a identification type',
      passport: 'Passport',
      attentionAlert: 'Attention!'
    },
    bookingConfirmation: {
      paymentConfirmationTitle: 'Payment Confirmation',
      creditCard: 'Credit Card',
      authorizationCode: 'Authorization Code',
      date: 'Date',
      bookingNumber: 'Booking Number',
      confirmationSentTo: 'confirmation email sent to',
    },
    bookingNotification: {
      loadingBooking: 'Loading booking',
      bookingSelected: 'Booking Selected',
      bookingCanceled: 'Booking Canceled',
      bookingSuccessfullyCanceled: 'Your booking has been successfully canceled',
      bookingSuccessFullyCreated: 'Booking successfully created!',
      error: 'Error',
      errorMessage: 'Error Message',
      scheduleError: 'Schedule error',
      scheduleErrorMessage: 'You can only book within a range of 7 months from the current date.',
      amountExceeded: 'Amount of people exceeded',
      limitPaxAllow: 'the limit amount of people allowed is',
      amountPaxError: 'Amount of people error',
      miniumPaxError: 'The minimum amount of people allowed is 1',
      companyInfo: 'Company info was found!',
      companyNotFound: 'Company info could not be found, please check your document number and try again!',
      bookingRefundTitle: 'Booking Refund Successful',
      bookingRefundCreated: 'Your refund request has been successfully created',
      bookingRefundError: 'Booking Refund Error',
    },
    bookingTimer: {
      timerTitle: 'Your booking will expire soon!',
      timerSubtitle: 'Do you want to continue?',
      timeMessage: 'Remaining time to complete your booking',
      timerUpdated: 'Booking hold timer successfully updated!'
    },
    cancelBooking: {
      cancelTitle: 'Cancel Booking',
      cancelMessage: 'Are you sure you want to cancel the booking for the event',
    },
    participantCategory: {
      adultsTitle: 'Specify number of adults',
      childrenTitle: 'Specify number of children',
      infantsTitle: 'Specify number of infants and nannies'
    },
    otpValidation: {
      otpTitle: 'Collaborator Validation',
      employeeCode: 'Employee code',
      otpSentTitle: 'OTP Code Sent!',
      otpSentMessage: 'A verification code has been sent to the following email address',
      otpSendMessageSequence: ', please enter the code in the following input.',
      otpCode: 'OTP Code'
    }
  },
}
