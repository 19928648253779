// this form is to determine the details related to the user, which will return and push

<template>

<div class="guest_info_container">
    <div class="row" v-if="!guest_info.is_main">
        <div class="col-md-1 primary_col mt-5">
            <h5>
                # {{guest_info.position+1}}
            </h5>
        </div>
        <div class="col-md-2 primary_col mt-4">
            <OptionSelect :guest=guest_info />
        </div>
        <div class="col-md-4 primary_col">
            <Input input_type="text"
                :label_title="$t('bookingForm.formName')"
                :disable_input="readonly_input"
                v-model="firstName"
                :html_unique="`guest_first_name_${guest_info.position+1}`"
                :validation="ruleset_validation.BookingForm.guests[guest_info.position].first_name"/>
        </div>
        <div class="col-md-4">
            <Input input_type="text"
                :label_title="$t('bookingForm.formLastname')"
                v-model="lastName"
                :required_f='false'
                :disable_input="readonly_input"
                :html_unique="`guest_last_name_${guest_info.position+1}`"
                :validation="ruleset_validation.BookingForm.guests[guest_info.position].last_name" />
        </div>
    </div>
    <div v-else>
        <div class="col-md-1 primary_col mt-5">
            <h5>
                # {{guest_info.position+1}}
            </h5>
        </div>
        <div class="col-md-2 primary_col mt-4">
            <OptionSelect :guest=guest_info :label_title="$t('participantInfo.partipantType')" />
        </div>
    </div>
</div>
</template>

<script>
import Input from './Input.vue'
import {mapActions, mapGetters} from 'vuex';
import OptionSelect from './optionSelect.vue'
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators';

export default {
    name: 'GuestForm',
    setup() {
      return {v$: useVuelidate()}
       },
    components:{
        Input,
        OptionSelect
    },
    props: {
        guest_info:{
            type:Object
        },
        readonly_input: {
            type:Boolean,
            default: false,
        },
    },
    methods:{
        ...mapActions(['fillGuestInfo'])
    },
    validations(){
      return {
        firstName: { required },
        lastName: { required }
      }
    },
    computed:{
        ...mapGetters(['booker','ruleset_validation']),
        firstName:{
            get(){
                let return_value  = (this.guest_info.is_main)? booker.first_name:this.guest_info.first_name;
                return return_value
            },
            set(value){
                if (this.guest_info.is_main){
                     value = booker.first_name
                 }
                var sender = {key:'first_name',
                              val: value,
                              guest: this.guest_info}
                this.$store.dispatch('fillGuestInfo', sender);
            }
        },
        lastName:{
            get(){
                let return_value  = (this.guest_info.is_main)? booker.last_name:this.guest_info.last_name;
                return return_value;
            },
            set(value){
                 if (this.guest_info.is_main){
                     value = booker.last_name
                 }
                 var sender = {key:'last_name',
                              val: value,
                              guest: this.guest_info}
                this.$store.dispatch('fillGuestInfo', sender);
            }
        },
    },

}
</script>

<style scoped>

.guest_info_container{
    margin-bottom: 15px;
}
.select_container{
    padding-left: 10px;
    margin-bottom: 5px;
}
</style>
