// creates a selectable and interact with the exisiting vuex variables.
<template>
<label for="">
    <p class="label_formater"> {{label_title}} </p>
    <!-- <select v-if="guest.is_main" :v-model=primaryGuest @input=testUpdateSelect >
        <option value="true" selected>Myself</option>
        <option value="false" >Other person</option>
    </select>

    <select v-else :v-model=primaryGuest  @input=testUpdateSelect >
        <option value="false" selected>Other person </option>
    </select> -->

    <vSelect :options="option" v-model="primaryGuest" class='select_renderer'
    :clearable='false'/>

</label>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import vSelect from 'vue-select';

export default {
    name:'OptionSelect',
    components:{
        vSelect
    },
    data(){
            return{
                option: this.getOptions()
            }
        },
    props:{
        label_title:{
            type: String,
            default: 'Participant type'
        },
        guest:{
            type: Object
        },
    },
     methods: {
            ...mapActions(['updateIsMain']),
            getOptions(){
                var result = []
                if (this.guest.is_main){
                    result = [
                                {
                                    label: this.$t('participantInfo.otherPerson'),
                                    code: false
                                }]
                }
                else{
                    result = [{
                                label: this.$t('participantInfo.mySelf'),
                                code: true
                            },
                            {
                                label: this.$t('participantInfo.otherPerson'),
                                code: false
                            }]
                }

                return result

            }
        },
    computed: {
            ...mapGetters(['']),
            primaryGuest:{
                get(){
                    this.option = this.getOptions();
                    return (this.guest.is_main)?{
                                label: this.$t('participantInfo.mySelf'),
                                code: true
                            }: this.option[1]
                },
                set(value){
                    let data = {is_main:value.code, g: this.guest}
                    this.$store.dispatch('updateIsMain', data);
                }
            }
        }
}
</script>

<style scoped>
.label_formater {
	margin: 0px 0px 2px 0px;
    font-size: 13px;
    text-align: left;

}
.select_renderer{
    cursor: pointer;
}
</style>
