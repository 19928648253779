// Basically here we will manage the number of people that can be selected

<template>
  <div class="container">
    <h6 class="title_container">
      <strong>{{ $t(onGetPeopleCategoryTitle(peopleCategory).title) }} </strong>
      <span v-if="unitPrice.amount !== 0">({{ unitPrice.currency }} ${{ unitPrice.amount }})</span>
    </h6>
    <div class="input-group">
      <button class="btn btn-success" type="button" @click="decreaseNumberPeople(peopleCategory)">
        <i class="cil-minus"></i>
      </button>
      <select class="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
        <option selected>{{onGetPeopleCategoryTitle(peopleCategory).number }}</option>
      </select>
      <button class="btn btn-success" type="button" @click="increaseNumberPeople(peopleCategory)">
        <i class="cil-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Button from '../components/Button.vue'
import {mapGetters, mapActions} from 'vuex';
export default {
  name:'NumberPeople',
  computed: mapGetters(['booking', 'getPeopleCategoryConfig', 'numberPeople']),
  props:{
    minPeopleCount:{
      type: Number,
      default: 1
    },
    maxPeopleCount:{
      type: Number
    },
    title:{
      type:String,
    },
    unitPrice: {
    },
    peopleCategory: {
      type:String,
      default: 'Cadults'
    }
  },
  methods:{
    ...mapActions['changeNumberGuests', 'changeDisplayLoader'],
    increaseNumberPeople(peopleCategoryId){
      if (this.onGetPeopleCategoryTitle(peopleCategoryId).number < this.maxPeopleCount){
        const operation = { operation: 'increase', peopleCategoryId: peopleCategoryId }
        this.$store.dispatch('changeNumberGuests', operation)
      }
      else{
        this.$toast.add({
          severity:'error',
          summary: this.$t('bookingNotification.amountExceeded'),
          detail: `${this.$t('bookingNotification.limitPaxAllow')} ${this.maxPeopleCount}`,
          life:7000
        });
      }
    },
    decreaseNumberPeople(peopleCategoryId){
      if (this.numberPeople > 1){
        const operation = { operation: 'decrease', peopleCategoryId: peopleCategoryId }
        this.$store.dispatch('changeNumberGuests', operation);
        // continue with the search
      }
      else{

        this.$toast.add({severity:'error',
          summary: this.$t('bookingNotification.amountPaxError'),
          detail: this.$t('bookingNotification.miniumPaxError'),
          life:7000})
      }
    },
    onGetPeopleCategoryTitle(peopleCategoryId) {
      return this.getPeopleCategoryConfig.find(option => option.peopleCategoryId.includes(peopleCategoryId));
    },
  },
  components:{
    Button
  }

}
</script>

<style scoped>
.booking_people_number {
  margin-left: 53px;
  margin-top: 12px;
  width:4%;
}
.btn_holder{
  margin-left: 10px;
  width:15px;
}
.title_container{
  text-align: left;
}
#inputGroupSelect04 {
  text-align: center;
}
</style>
