import { createI18n } from 'vue-i18n';
import messages from '@/i18n/messages';


const locale = window.localStorage.currentLanguage ? window.localStorage.currentLanguage : 'en_US';

const i18n = createI18n({
    locale: locale,
    fallbackLocale: locale,
    messages
});

export default i18n;
