<template>
  <div>
    <div v-if="date_selected">
      <div class="row justify-content-between" v-if="!event_selected.bookingEventConfiguration.is_multi_days">
        <!-- Date Section -->
        <div class="col-6 col-md-6 col-sm-6 col-xs-6" style="text-align: left">
          <p class="selected_date">
            {{ formattedDate() }}
          </p>
        </div>
        <!-- Price Section -->
        <div class="col-6 col-md-6 col-sm-6 col-xs-6 text-md-right" style="text-align: right">
          <strong class="total_amount">USD{{ availability_list[0]?.formated_price }}</strong>
        </div>
      </div>
           <!-- Events Section -->
      <div class="events_wrapper">
        <div class="events_container" v-if="availability_list.length > 0">
            <h5 class="card-title title">
              {{ $t('bookingCreate.availableEntries') }}
            </h5>
            <AvailableEvent
              v-for="(event, index) in availability_list"
              :key="index"
              :availability="event"
              class="event-item"
            />
        </div>
        <div class="not_items_container" v-else>
          <h3>{{ $t('bookingCreate.noSpotAvailable') }}</h3>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('bookingCreate.selectADate') }}</h3>
    </div>

  </div>
</template>

<script>
import AvailableEvent from './AvailableEvent.vue';
import { mapGetters } from 'vuex';

export default {
  name: "AvailableEventList",
  components: {
    AvailableEvent,
  },
  computed: mapGetters(['availability_list', 'event_selected', 'date_selected', 'selected_language']),
  methods: {
    cancelBooking() {
      // Your cancel action logic here
    },
    formattedDate() {
      const locale = this.selected_language.replace('_', '-');
      return new Date(this.date_selected).toLocaleDateString(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
  },
};
</script>
<style scoped>
.main_container {
  text-align: left;
}

.selected_date {
  color: green;
  font-size: 1.2rem;
  font-weight: 500;
}

.total_amount {
  font-size: 1.2rem;
}

.events_container {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  max-height: 200px;
}

.event-item {
  margin-bottom: 1rem;
}

.cancel-button {
  width: 100%; /* Full-width on smaller screens */
}

@media (max-width: 1400px) {
  h5 {
    font-size: 1.4rem; /* Reduce font size for smaller screens */
  }
  .selected_date, .total_amount {
    font-size: 1.2rem;
  }
}
@media (max-width: 1200px) {
  h5 {
    font-size: 1.4rem; /* Reduce font size for smaller screens */
  }
  .selected_date, .total_amount {
    font-size: 1rem;
  }
}

.title{
  text-align: left;
  margin-bottom:15px;
  margin-top:10px;
}
</style>
